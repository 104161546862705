<template>
  <div class="user-center">
    <div class="user-center-header">
      <router-link v-if="accountType === 'BUY'" to="/home/HomePage">
        <img class="logo" style="height:100%" src="./image/logo.png" />
      </router-link>
      <router-link v-else to="/home/HomePage">
        <img class="logo" style="height:100%" src="./image/sell-logo.png" />
      </router-link>
      <div class="right">
        <span v-if="accountType === 'BUY'" style="color:#188bff" class="title">买家中心</span>
        <span v-else style="color:#595cd4" class="title">供应商中心</span>
        <div style="display: flex;align-items: center;">
          <img v-if="userInfo.byTClientInfoId.logo" class="avatar" :src="userInfo.byTClientInfoId.logo" />
          <img v-else class="avatar" src="./image/logo.png" />
          <span style="margin:0 32px 0 13px;color:#3e4767;font-size:15px">
            <span>您好，</span>
            <span>{{ userInfo.loginUser.users }}</span>
          </span>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link"> 更多平台<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="/home/HomePage">铝拍拍商城</el-dropdown-item>
              <el-dropdown-item command="/User/workbench">{{ getName }}</el-dropdown-item>
              <el-dropdown-item command="service">企业服务中心</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <Nav class="user-center-nav" />
    <div class="user-center-main">
      <router-view style="margin: 0 auto;width:1560px" />
    </div>

    <Foot />
  </div>
</template>

<script>
import {selectByPrimaryKey} from './api.js';
import {queryEnterpriseAccount} from '@/layout/api.js';
import Nav from './component/new-nav';
import Foot from '@/components/Foot';
import {financeSystemUrl} from '@/api/config';
export default {
  components: {
    Nav,
    Foot
  },
  data() {
    return {
      userInfo: {
        byTClientInfoId: {},
        loginUser: {}
      }
    };
  },
  created() {
    this.getSelectByPrimaryKey();
  },
  mounted() {
    if (localStorage.getItem('MenuType')) this.$store.commit('changeMenuType', localStorage.getItem('MenuType'));
  },
  computed: {
    getName() {
      if (this.accountType == 'SELL') {
        return '买家中心';
      } else {
        return '供应商中心';
      }
    },
    accountType() {
      return this.$store.getters['getterMenuType'];
    }
  },
  methods: {
    async getSelectByPrimaryKey() {
      let res = await selectByPrimaryKey({id: localStorage.getItem('userId')});
      if (res.errno === 0) this.userInfo = res.data;
    },
    handleCommand(command) {
      if (command == '/User/workbench') {
        if (this.getName == '供应商中心') {
          if (localStorage.getItem('accountType') !== 'SELL') {
            //当用户未成为供应商时，提示不能进行此操作
            this.$message.warning('您还未成为供应商，请先申请成为供应商再进行操作。');
            return;
          }
          this.$store.commit('changeMenuType', 'SELL');
          localStorage.setItem('MenuType', 'SELL');
        } else {
          this.$store.commit('changeMenuType', 'BUY');
          localStorage.setItem('MenuType', 'BUY');
        }
      }
      if (command == 'service') {
        queryEnterpriseAccount().then(res => {
          if (res.errno === 0) {
            if (res.data > 1) {
              window.open(financeSystemUrl);
              return;
            } else {
              this.$message('请先添加子账号！');
              this.$router.push({
                path: '/User/SubAccount'
              });
              return;
            }
          }
        });
      }
      this.$router.push({path: command});
    }
  }
};
</script>

<style lang="less" scoped>
.user-center {
  &-header {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    box-shadow: 1px 3px 10px rgba(46, 110, 175, 0.1);
    .logo {
      height: 90px;
      width: 254px;
    }
    .right {
      width: 100%;
      background-color: #fff;
      padding: 0 45px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-family: 'DOUYU';
        font-size: 23px;
      }
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #3e4767;
        font-size: 15px;
      }
    }
  }
  &-nav {
    z-index: 99;
    position: fixed;
    top: 90px;
    left: 0;
  }
  &-main {
    // min-width: 1559px;
    // margin: 90px auto 254px auto;
    padding: 130px 0 35px 254px;
    // padding: 130px 45px 35px 254px;
  }
}
</style>

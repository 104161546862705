var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-center" },
    [
      _c(
        "div",
        { staticClass: "user-center-header" },
        [
          _vm.accountType === "BUY"
            ? _c("router-link", { attrs: { to: "/home/HomePage" } }, [
                _c("img", {
                  staticClass: "logo",
                  staticStyle: { height: "100%" },
                  attrs: { src: require("./image/logo.png") },
                }),
              ])
            : _c("router-link", { attrs: { to: "/home/HomePage" } }, [
                _c("img", {
                  staticClass: "logo",
                  staticStyle: { height: "100%" },
                  attrs: { src: require("./image/sell-logo.png") },
                }),
              ]),
          _c("div", { staticClass: "right" }, [
            _vm.accountType === "BUY"
              ? _c(
                  "span",
                  { staticClass: "title", staticStyle: { color: "#188bff" } },
                  [_vm._v("买家中心")]
                )
              : _c(
                  "span",
                  { staticClass: "title", staticStyle: { color: "#595cd4" } },
                  [_vm._v("供应商中心")]
                ),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _vm.userInfo.byTClientInfoId.logo
                  ? _c("img", {
                      staticClass: "avatar",
                      attrs: { src: _vm.userInfo.byTClientInfoId.logo },
                    })
                  : _c("img", {
                      staticClass: "avatar",
                      attrs: { src: require("./image/logo.png") },
                    }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      margin: "0 32px 0 13px",
                      color: "#3e4767",
                      "font-size": "15px",
                    },
                  },
                  [
                    _c("span", [_vm._v("您好，")]),
                    _c("span", [_vm._v(_vm._s(_vm.userInfo.loginUser.users))]),
                  ]
                ),
                _c(
                  "el-dropdown",
                  { on: { command: _vm.handleCommand } },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _vm._v(" 更多平台"),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "/home/HomePage" } },
                          [_vm._v("铝拍拍商城")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "/User/workbench" } },
                          [_vm._v(_vm._s(_vm.getName))]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "service" } },
                          [_vm._v("企业服务中心")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("Nav", { staticClass: "user-center-nav" }),
      _c(
        "div",
        { staticClass: "user-center-main" },
        [
          _c("router-view", {
            staticStyle: { margin: "0 auto", width: "1560px" },
          }),
        ],
        1
      ),
      _c("Foot"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="nav-wrap" ref="content">
    <div ref="topFilling" :style="{height: '35px', 'background-color': accountType == 'BUY' ? '#0a83f1' : '#424a74'}"></div>
    <div class="left-filling" ref="leftFilling" :style="{height: `${height}px`, 'background-color': accountType == 'BUY' ? '#0a83f1' : '#424a74'}"></div>
    <ul class="nav-wrap-list" ref="list">
      <li
        :class="['list-child', 'mouseStyle', chooseNum == 'leftFilling' ? 'hover' : '']"
        :style="{'background-color': accountType == 'BUY' ? '#0a83f1' : '#424a74', color: isBuyColor('leftFilling')}"
        @click.stop="changeOneTab"
      >
        <i :class="['icon', isBuyBack('leftFilling')]"></i>
        <span class="name" @click="linkTo('/User/workbench')">我的工作台</span>
      </li>
      <li v-for="(item, index) in listData" :key="index" :ref="`list${index}`">
        <p @click="clickTitle(index)" class="list-child mouseStyle" :style="{'background-color': accountType == 'BUY' ? '#0a83f1' : '#424a74'}" :ref="`listTitle${index}`">
          <i :class="['icon', `${item.type}`]"></i>
          <span class="name">{{ item.name }}</span>
          <i style="margin-left: 62px" :class="isShowChild(index) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
        </p>
        <div
          :class="['list-child', 'operate', chooseNum == `list${index}${j}` ? 'hover' : '', isShowChild(index) ? 'flex' : 'none']"
          :style="{'background-color': accountType == 'BUY' ? '#0a83f1' : '#424a74', color: isBuyColor(`list${index}${j}`)}"
          :ref="`listChild${j}listTitle${index}`"
          v-for="(element, j) in item.child"
          :key="j"
          @click.stop="changeTab(item, j, index, element)"
        >
          <span class="my-content">
            <i :style="{'border-color': isBuyColor(`list${index}${j}`)}" class="rand"></i>
            <span style="font-size: 18px">{{ element.name }}</span>
          </span>
        </div>
      </li>
    </ul>
    <div class="filling" :style="{'background-color': accountType == 'BUY' ? '#0a83f1' : '#424a74'}" ref="filling"></div>
  </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector';
import {myAuthority} from '../api.js';
export default {
  data() {
    return {
      equipmentAuthority: false,
      itemSelected: '',
      listData: [
        {
          name: '基础信息',
          type: 'msg',
          child: [
            {
              name: '企业信息',
              path: '/User/CorporateInfo'
            },
            {
              name: '收货地址',
              path: '/User/Address'
            }
          ]
        },
        {
          name: '竞卖管理',
          type: 'sell',
          child: []
        },
        {
          name: '竞买管理',
          type: 'buy',
          child: []
        },
        {
          name: '竞标管理',
          type: 'bind',
          child: []
        },
        {
          name: '订单管理',
          type: 'order',
          child: [
            {
              name: '普通订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/MyOrder'
            },
            {
              name: '竞买/竞卖订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/ReqOrder'
            },
            {
              name: '竞标订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/BidOrder'
            }
          ]
        },
        {
          name: '合同管理',
          type: 'contract',
          child: [
            {
              name: '我的合同',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/SellMyContract?customer=sellder'
            }
          ]
        }
      ],
      selected: '',
      chooseNum: 'leftFilling',
      height: 0,
      showMyTable0: '',
      showMyTable1: '',
      showMyTable2: '',
      showMyTable3: '',
      showMyTable4: '',
      showMyTable5: '',
      showMyTable6: '',
      showMyTable7: '',
      chooseMyTab: '',
      clHeight: ''
    };
  },
  created() {
    if (localStorage.getItem('MenuType')) this.$store.commit('changeMenuType', localStorage.getItem('MenuType'));
    this.getMyAuthority();
  },
  mounted() {
    // 监听侧边栏ul的高度
    const erdm = elementResizeDetectorMaker();
    this.$nextTick(() => {
      erdm.listenTo(this.$refs.list, element => {
        this.height = element.offsetHeight;
        if (this.clHeight - 90 - 35 - this.height > 0) {
          this.$refs.filling.style['height'] = this.clHeight - 90 - 35 - this.height + 'px';
        } else {
          this.$refs.filling.style['height'] = 20 + 'px';
        }
      });
    });
    // 获取可见区域高度
    this.clHeight = document.documentElement.clientHeight || document.body.clientHeight;
    this.$nextTick(() => {
      this.$refs.content.style['height'] = this.clHeight - 90 + 'px';
    });
    this.changeOneTab();
  },
  computed: {
    accountType() {
      return this.$store.getters['getterMenuType'];
    }
  },
  watch: {
    $route: function () {
      this.selected = this.$route.path;
    },
    accountType: function (val) {
      this.initMenu(val);
    }
  },

  methods: {
    async getMyAuthority() {
      let res = await myAuthority({isPermissions: 'user:sell:contractManger'});
      if (res.errno === 0) {
        this.equipmentAuthority = res.data;
        this.initMenu(this.accountType);
      }
    },
    isBuyColor(item) {
      if (this.accountType == 'BUY') {
        if (this.chooseNum == item) return '#0a83f1';
        if (this.chooseNum != item) return '#fff';
      } else {
        if (this.chooseNum == item) return '#424a74';
        if (this.chooseNum != item) return '#fff';
      }
    },
    isBuyBack(item) {
      if (this.accountType == 'BUY') {
        if (this.chooseNum == item) return 'my-workbench-hover';
        if (this.chooseNum != item) return 'my-workbench';
      } else {
        if (this.chooseNum == item) return 'my-workbench-sell-hover';
        if (this.chooseNum != item) return 'my-workbench';
      }
    },
    changeOneTab() {
      this.chooseNum = 'leftFilling';
      this.$refs.topFilling.style['border-bottom-right-radius'] = '20px';
      this.$refs.listTitle0[0].style['border-top-right-radius'] = '20px';
    },
    linkTo(path) {
      this.$router.push({path});
    },
    initializationNav() {
      this.listData = [
        {
          name: '基础信息',
          type: 'msg',
          child: [
            {
              name: '企业信息',
              path: '/User/CorporateInfo'
            },
            {
              name: '收货地址',
              path: '/User/Address'
            }
          ]
        },
        {
          name: '竞卖管理',
          type: 'sell',
          child: []
        },
        {
          name: '竞买管理',
          type: 'buy',
          child: []
        },
        {
          name: '竞标管理',
          type: 'bind',
          child: []
        },
        {
          name: '订单管理',
          type: 'order',
          child: [
            {
              name: '普通订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/MyOrder'
            },
            {
              name: '竞买/竞卖订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/ReqOrder'
            },
            {
              name: '竞标订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/BidOrder'
            }
          ]
        },
        {
          name: '合同管理',
          type: 'contract',
          child: [
            {
              name: '我的合同',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/SellMyContract?customer=sellder'
            }
          ]
        }
      ];
    },
    initMenu(accountType) {
      this.initializationNav();
      let parentId = localStorage.getItem('parentId');
      let enterpriseId = localStorage.getItem('enterpriseId');
      if (accountType && accountType == 'BUY') {
        //  竞卖部分添加
        this.listData[1].child.push({
          name: '参与的竞卖',
          path: '/User/MyPartBidding'
        });
        //  竞买部分添加
        this.$set(this.listData[2], 'child', [
          {
            name: '发布竞买',
            path: '/User/Publish'
          },
          {
            name: '我的竞买',
            path: '/User/MyPublish'
          }
        ]);
        //  竞标部分添加
        this.$set(this.listData[3], 'child', [
          {
            name: '发布竞标',
            path: '/User/PublishCompetitive'
          },
          {
            name: '我的竞标',
            path: '/User/MyCompetitive'
          }
        ]);
      } else {
        //  竞卖部分添加
        this.$set(this.listData[1], 'child', [
          {
            name: '发布竞卖',
            path: '/User/PublishBidding'
          },
          {
            name: '我的竞卖',
            path: '/User/MyBidding'
          }
        ]);
        //  竞买部分添加
        this.$set(this.listData[2], 'child', [
          {
            name: '参与的竞买',
            path: '/User/MyPartPublish'
          }
        ]);
        //  竞标部分添加
        this.$set(this.listData[3], 'child', [
          {
            name: '参与的竞标',
            path: '/User/MyPartCompetitive'
          }
        ]);
        //  店铺管理部分添加
        this.$set(this.listData, 4, {
          name: '店铺管理',
          type: 'store',
          child: [
            {
              name: '店铺信息',
              path: '/User/StoreInfo'
            },
            {
              name: '添加产品',
              path: '/User/AddProduct'
            },
            {
              name: '产品列表',
              path: '/User/ProductList'
            }
          ]
        });
        //  订单管理部分添加
        this.$set(this.listData, 5, {
          name: '订单管理',
          type: 'order',
          child: [
            {
              name: '普通订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/MyOrder'
            },
            {
              name: '竞买/竞卖订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/ReqOrder'
            },
            {
              name: '竞标订单',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/BidOrder'
            }
          ]
        });
        //  合同管理部分添加
        this.$set(this.listData, 6, {
          name: '合同管理',
          type: 'contract',
          child: [
            {
              name: '我的合同',
              query: {customer: this.accountType == 'BUY' ? 'buyder' : 'sellder'},
              path: '/User/SellMyContract?customer=sellder'
            }
          ]
        });
        console.log(this.equipmentAuthority);
        if (this.equipmentAuthority) {
          //  企业设备部分添加
          this.$set(this.listData, 7, {
            name: '企业设备',
            type: 'equipment',
            child: [
              {
                name: '设备合同',
                path: '/User/device'
              }
            ]
          });
        }
      }
      if (parentId == 0) {
        //父帐号
        this.listData[0].child.unshift({
          name: '认证信息',
          path: '/User/AttestInfo'
        });
        if (enterpriseId !== 0) {
          this.listData[0].child.unshift({
            name: '账户管理',
            path: '/User/SubAccount'
          });
        }
      }
    },
    isShowChild(index) {
      return this[`showMyTable${index}`] == `list${index}`;
    },
    clickTitle(index) {
      if (this[`showMyTable${index}`] == `list${index}`) {
        this.$set(this, `showMyTable${index}`, '');
      } else {
        this.$set(this, `showMyTable${index}`, `list${index}`);
      }
      //   this.initialization();
    },
    initialization() {
      this.$refs.list.childNodes.forEach(item => {
        item.style['border-bottom-right-radius'] = 0;
        item.style['border-top-right-radius'] = 0;
        item.childNodes.forEach(e => {
          e.style['border-bottom-right-radius'] = 0;
          e.style['border-top-right-radius'] = 0;
        });
      });
      this.$refs.filling.style['border-top-right-radius'] = 0;
      this.$refs.topFilling.style['border-bottom-right-radius'] = 0;
    },
    // 选中某一项触发事件
    changeTab(item, j, index, element) {
      this.itemSelected = `list${index}`;
      this.chooseNum = `list${index}${j}`;
      // 初始化
      this.initialization();
      //   如果不是第一个也不是最后一个
      if (j !== 0 && j !== item.child.length - 1) {
        this.$refs[`listChild${j - 1}listTitle${index}`][0].style['border-bottom-right-radius'] = '20px';
        this.$refs[`listChild${j + 1}listTitle${index}`][0].style['border-top-right-radius'] = '20px';
      }
      //   如果是第一个
      if (j == 0) {
        this.$refs[`listTitle${index}`][0].style['border-bottom-right-radius'] = '20px';
      }
      //   如果是第一个且总数不止一个
      if (j == 0 && item.child.length > 1) {
        this.$refs[`listChild${j + 1}listTitle${index}`][0].style['border-top-right-radius'] = '20px';
      }
      //   如果是小分类最后一个且不是大类的最后一个
      if (j == item.child.length - 1 && index !== this.listData.length - 1) {
        this.$refs[`listTitle${index + 1}`][0].style['border-top-right-radius'] = '20px';
      }
      //   如果是大分类最后一个且是小分类的最后一个
      if (j == item.child.length - 1 && index == this.listData.length - 1) {
        this.$refs.filling.style['border-top-right-radius'] = '20px';
      }
      //   如果是最后一个且总数不止一个
      if (j == item.child.length - 1 && item.child.length > 1) {
        this.$refs[`listChild${j - 1}listTitle${index}`][0].style['border-bottom-right-radius'] = '20px';
      }
      if (element.query) {
        this.$router.push({path: element.path, query: element.query});
      } else {
        this.$router.push({path: element.path});
      }
    }
  }
};
</script>

<style lang="less" scoped>
// .loop(@count) when(@count>0){
// 　　　　h@{count}{
// 　　　　margin:@count*10px ;
// 　　}
// 　　.loop((@count - 1));//注意变量和运算符之间一定要有空格
// 　　}

// 　　div{
// 　　　　.loop(6)
// 　　}
.nav-wrap {
  overflow-y: auto;
  background-color: #f5f5f5;
  width: 254px;
  &::-webkit-scrollbar {
    width: 0px;
  }
  //   设置滚动条的颜色
  //   &::-webkit-scrollbar-thumb {
  //     background-color: rgb(66, 74, 116);
  //     // border-radius: 5px;
  //   }

  .filling {
    height: 100%;
  }
  .left-filling {
    height: 100%;
    width: 80px;
    background-color: #0a83f1;
  }
  .hover {
    color: #0a83f1;
    background-color: #f5f5f5 !important;
  }
  .bending-bottom {
    border-bottom-right-radius: 20px;
  }
  .bending-top {
    border-top-right-radius: 20px;
  }
  &-list {
    box-sizing: border-box;
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    padding-left: 10px;
    .list-child {
      color: #fff;
      background-color: #0a83f1;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      height: 72px;
      display: flex;
      align-items: center;
      .icon {
        width: 25px;
        height: 25px;
        margin: 0 17px 0 27px;
      }
      .name {
        font-size: 18px;
      }
    }
  }
  .rand {
    display: inline-block;
    width: 5px;
    height: 5px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 20px 0 27px;
  }
  .rand-hover {
    border-color: #0a83f1;
  }
  .operate {
    .my-content {
      padding-left: 12px;
    }
    span {
      cursor: pointer;
    }
  }
  .my-content:hover {
    padding-left: 22px;
    transition: all 0.4s ease;
  }
  .my-workbench {
    background: url('../image/control.png') no-repeat center/20px 20px;
  }
  .my-workbench-hover {
    background: url('../image/control-hover.png') no-repeat center/20px 20px;
  }
  .my-workbench-sell-hover {
    background: url('../image/sell-control-hover.png') no-repeat center/20px 20px;
  }
  .msg {
    background: url('../image/msg.png') no-repeat center/20px 20px;
  }
  .sell {
    background: url('../image/sell.png') no-repeat center/20px 20px;
  }
  .bind {
    background: url('../image/bind.png') no-repeat center/20px 20px;
  }
  .order {
    background: url('../image/order.png') no-repeat center/20px 20px;
  }
  .contract {
    background: url('../image/contract.png') no-repeat center/20px 20px;
  }
  .store {
    background: url('../image/store.png') no-repeat center/20px 20px;
  }
  .buy {
    background: url('../image/buy.png') no-repeat center/20px 20px;
  }
  .equipment {
    background: url('../image/equipment.png') no-repeat center/20px 20px;
  }
}
</style>
